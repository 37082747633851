import { get, has, isEmpty, orderBy, sortBy } from 'lodash';
import {
  FETCH_ALL_OCCUPATIONS,
  FETCH_CATEGORY,
  FETCH_LEVEL,
  FETCH_SCHOOL_CONFIG,
  FETCH_ALL_TAGS,
  FETCH_ALL_CENTRES,
  FETCH_ALL_SERVICE_LEVELS,
  FETCH_ALL_CENTRE_LEVELS,
  FETCH_APP_CONFIG,
  FETCH_ECDA_COMBINED_WORKING_STATUS,
  FETCH_ALL_REGISTRATION_CONFIG,
  FETCH_SUGGEST_LEVELS,
  FETCH_SUGGEST_LEVELS_SUCCESS,
  FETCH_SUGGEST_LEVELS_ERROR,
} from '../actions/actionTypes';

const getOccupationList = (salariedEmployee, selfEmployed) => {
  const selfEmployedArr = [];
  const selfEmployedGroups = Object.keys(selfEmployed);
  selfEmployedGroups.forEach(group => {
    const occupations = selfEmployed[group].data.map(item => ({
      label: item.label,
      value: item.label,
      ID: item.ID,
      description: item.description,
    }));

    selfEmployedArr.push(...occupations);
  });

  const salariedEmployeeArr = salariedEmployee.map(item => ({
    label: item.label,
    value: item.label,
    ID: item.ID,
    description: item.description,
  }));

  return {
    selfEmployed: selfEmployedArr,
    salariedEmployee: salariedEmployeeArr,
  };
};

export const fetchCategory = (state = {}, action) => {
  if (action.type === FETCH_CATEGORY) {
    const data = get(action, 'value.data.data.findAllConfigByCategory.data');
    const { type } = action.value.data;
    if (data) {
      if (data?.length > 0 && type === 'VP_Reason_For_Recall') {
        const otherIndex = data.findIndex(
          item => item.label.toLowerCase() === 'other'
        );
        data.push(data.splice(otherIndex, 1)[0]);
      }
      return Object.assign({}, state, {
        [type]: {
          ...action.value,
          data,
          fullData: get(
            action,
            'value.data.data.findAllConfigByCategory.fullData'
          ),
        },
      });
    }
    return Object.assign({}, state, {
      [type]: action.value,
    });
  }

  return state;
};

export const appConfig = (state = {}, action) => {
  if (action.type === FETCH_APP_CONFIG) {
    const response = action.value.data;
    if (!isEmpty(response)) {
      const configs = {};
      const houseHoldIncomeConfig = sortBy(
        get(response, 'houseHoldIncomeConfig.data', []),
        _item => parseInt(_item.label, 10)
      );
      const occupationList = getOccupationList(
        get(response, 'salaryEmployeeOccupationConfig.data', []),
        get(response, 'selfEmployedConfig.data', [])
      );
      const categoryKeys = Object.keys(response);
      categoryKeys.forEach(key => {
        if (
          key !== 'salaryEmployeeOccupationConfig' &&
          key !== 'selfEmployedConfig' &&
          key !== 'selfEmployedOccupationGroupConfig'
        ) {
          configs[key] = response[key].data;
        }
      });

      configs.occupationConfig = occupationList;
      configs.houseHoldIncomeConfig = houseHoldIncomeConfig;

      return Object.assign({}, state, {
        ...action.value,
        data: configs,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const getECDACombinedWorkingStatus = (state = {}, action) => {
  if (action.type === FETCH_ECDA_COMBINED_WORKING_STATUS) {
    const data = get(action, 'value.data.getECDACombinedWorkingStatus.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const registrationConfig = (state = {}, action) => {
  if (action.type === FETCH_ALL_REGISTRATION_CONFIG) {
    const response = action.value.data;
    if (!isEmpty(response)) {
      const configs = {};
      const categoryKeys = Object.keys(response);
      categoryKeys.forEach(key => {
        configs[key] = response[key].data;
      });

      return Object.assign({}, state, {
        ...action.value,
        data: configs,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const allOccupations = (state = {}, action) => {
  if (action.type === FETCH_ALL_OCCUPATIONS) {
    if (has(action, 'value.data.data')) {
      const selfEmployedArr = [];
      const salariedEmployeeArr = [];
      const responseData = action.value.data.data;
      const occupationKeys = Object.keys(action.value.data.data);
      // TODO: Refactor this part to be less complicated and more readable
      occupationKeys.forEach(occupationType => {
        const occupationTypeKeys = Object.keys(responseData[occupationType]);
        occupationTypeKeys.forEach(eachOccupation => {
          if (has(responseData[occupationType], `[${eachOccupation}].data`)) {
            responseData[occupationType][eachOccupation].data.forEach(
              occupation => {
                const occDetail = {
                  label: occupation.label,
                  value: occupation.label,
                  ID: occupation.ID,
                  description: occupation.description,
                };
                if (occupationType === 'selfEmployed') {
                  selfEmployedArr.push(occDetail);
                } else {
                  salariedEmployeeArr.push(occDetail);
                }
              }
            );
          }
        });
      });

      return Object.assign({}, state, {
        ...action.value,
        data: {
          selfEmployed: selfEmployedArr,
          salariedEmployee: salariedEmployeeArr,
        },
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const levelsConfig = (state = {}, action) => {
  if (action.type === FETCH_LEVEL) {
    const data = get(action, 'value.data.data.suggestLevel');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const schoolConfig = (state = {}, action) => {
  if (action.type === FETCH_SCHOOL_CONFIG) {
    const data = get(action, 'value.data.data.findAllSchoolConfig.data');
    const { key } = action.value.data;

    if (data) {
      const asObject = data.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
      }, {});

      return Object.assign({}, state, {
        [key]: {
          ...action.value,
          data,
          asObject,
        },
      });
    }

    return Object.assign({}, state, {
      [key]: action.value,
    });
  }

  return state;
};

export const fetchAllServiceLevel = (state = {}, action) => {
  if (action.type === FETCH_ALL_SERVICE_LEVELS) {
    const data = get(action, 'value.data.data.findAllLevelsConfig.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export const fetchAllCentre = (state = {}, action) => {
  if (action.type === FETCH_ALL_CENTRES) {
    const data =
      get(action, 'value.data.data.findAllCentreForSchool.data.length') &&
      get(action, 'value.data.data.findAllCentreForSchool.data');

    const orderedData = orderBy(data, ['label'], ['asc']);

    if (orderedData) {
      return Object.assign({}, state, {
        ...action.value,
        data: orderedData,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const fetchAllTags = (state = {}, action) => {
  if (action.type === FETCH_ALL_TAGS) {
    const data = get(action, 'value.data.data.findAllTags.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const centreLevels = (state = {}, action) => {
  if (action.type === FETCH_ALL_CENTRE_LEVELS) {
    const data = get(action, 'value.data.findAllCentreLevel.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export default fetchCategory;

export const suggestLevels = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUGGEST_LEVELS:
      return {
        ...state,
        ...action.value,
        inProgress: true,
      };
    case FETCH_SUGGEST_LEVELS_SUCCESS:
      return {
        ...state,
        ...action.value,
        inProgress: false,
      };
    case FETCH_SUGGEST_LEVELS_ERROR:
      return {
        ...state,
        ...action.value,
        inProgress: false,
      };
    default:
      return state;
  }
};
